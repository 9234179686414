<template>
  <v-main>
    <div
      :class="{ 'content mx-md-8': $route.name !== 'plugins_mobile_editor' }"
    >
      <div v-if="hasAnyPastDueInvoice && loggedUserIsAdmin" class="pa-2 mb-4">
        <past-due-payment-alert />
      </div>
      <router-view />
    </div>
  </v-main>
</template>

<script>
import PastDuePaymentAlert from "@/lib/calendesk-js-library/components/PastDuePaymentAlert.vue";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "DashboardCoreView",
  components: { PastDuePaymentAlert },
  mixins: [sharedActions],
  props: {
    hasAnyPastDueInvoice: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
