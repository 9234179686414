<template>
  <v-container class="form__container rounded-xl cd-outline" fluid>
    <v-row no-gutters justify="center">
      <v-col cols="12" md="8" class="d-flex align-center justify-center">
        <v-container>
          <v-row justify="center">
            <v-col class="text-center">
              <p class="text-h5">
                <v-icon size="48" color="orange">$alert</v-icon>
                {{ $trans("past_due_subscription_title_2") }}
              </p>
              <p>
                {{ $trans("past_due_subscription_subtitle_4") }}
              </p>
            </v-col>
          </v-row>
          <v-row class="mt-2" justify="center">
            <v-col class="d-flex align-center flex-wrap justify-center">
              <v-btn
                color="blue"
                depressed
                dark
                class="mr-2 mt-4"
                @click="goToPastDueDialog"
              >
                {{ $trans("retry_past_due_invoice_title") }}
              </v-btn>
              <v-btn
                color="blue"
                outlined
                class="mt-4"
                @click="handleOpenChatClick"
              >
                {{ $trans("wb_chat_with_us") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="d-none d-md-flex align-center justify-center"
      >
        <v-img
          contain
          :src="
            require('@/lib/calendesk-js-library/assets/payment-failed-2.png')
          "
          max-width="200"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { handleOpenChatClick } from "@/lib/calendesk-js-library/tools/helpers";
export default {
  name: "PastDuePaymentAlert",
  methods: {
    goToPastDueDialog() {
      this.$root.$emit("openPastDueView", false);
    },
    handleOpenChatClick,
  },
};
</script>
